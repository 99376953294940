<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'ContactUs',

    metaInfo: {
      title: 'Contact Us',
      titleTemplate: 'Howellcare Industries Sdn Bhd ContactUs',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { vmid: 'keyword', name: 'keyword', content: 'Lot 833, Jalan Mustaffa Al-Bakri, 36000 Teluk Intan, Perak Darul Ridzuan, Malaysia, inquiry@howellcareindustries.com, Glove Inquiry, Glove Project Inquiry, Feedback, nitrile, glove, manufacturing, products, international' },
        { vmid: 'description', name: 'description', content: 'Howellcare Industries Sdn Bhd is a one-stop service provider for nitrile glove manufacturing. Delivering excellence in manufacturing international standard nitrile gloves for personal barrier protection.' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'contact-us',
        'info-alt',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'contact',
      },
    },
  }
</script>
